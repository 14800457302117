<template>
  <div id="app-question-add" class="app-add">
    <el-table
        :data="records"
        :default-sort = "{prop: 'createdOn', order: 'ascending'}"
        v-show="tableShow"
        style="width: 100%" >
      <el-table-column   label="排名" align="center">
        <template slot-scope="scope">{{scope.$index+1}}</template>
      </el-table-column>
      <el-table-column prop="studentId" label="学员ID" align="center"></el-table-column>
      <el-table-column prop="nickname" label="昵称" align="center"></el-table-column>
      <el-table-column prop="username" label="手机号" align="center" width="150"></el-table-column>
      <el-table-column prop="integral" label="获得积分" align="center"></el-table-column>
      <el-table-column prop="balance" label="积分余额" align="center"></el-table-column>
      <el-table-column prop="taskName" label="任务名称" align="center" width="250"></el-table-column>
      <el-table-column prop="accuracy"  align="center" sortable width="140">
        <template slot="header" slot-scope="scope">
          <span>正确率({{allAccuracy}}%)</span>
        </template>
        <template slot-scope="scope">{{ scope.row.accuracy }}%</template>
      </el-table-column>
      <el-table-column prop="createdOn" label="完成时间" align="center" width="200" sortable></el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center" v-if="isShowOperation">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small">答题记录</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import {request, UrlEnum,TaskTypeEnum,TaskCategoryEnum} from "../../../../public/js/common-vue";

export default {
  name:"TaskDetail",
  data() {
    return {
      isShowOperation:false,
      total:0,
      page:1,
      size:1000,
      taskId:'',
      type:'',
      categoryId:'',
      records: [],
      allAccuracy:0,
      tableShow:false
    }
  },
  methods: {
    handleClick(row){
      this.$router.push('question-record?examId='+row.examId);
    },
    pageCurrentChange(val,e,form,jobExpLabel,pageform,searchVal) {
      this.page = val;
      this.initData();
    },
    initData() {
      var url = UrlEnum.STUDENT_INTEGRAL_TASK + "/exam-records?orderBy=id&orderType=asc&page="+this.page+"&size="+this.size+"&taskId=" + this.taskId;
      this.$http({
        method: "get",
        url: url,
        data: '',
      })
          .then((res) => {
            this.total = res.data.total;
            this.records = res.data.list;
            if(this.records.length>0){
              for(let i=0;i<this.records.length;i++){
                this.records[i].rank = (i+1)*this.page;
                this.allAccuracy += parseInt(this.records[i].accuracy);
              }
              this.allAccuracy = Math.round(this.allAccuracy / this.records.length);
            }
            this.tableShow=true;
          })
          .catch((res) => {
            this.loading = false;
            if (res.response != null) {
              const data = res.response.data;
              this.showMsgError(data.message);
            }
          })
    }
  },
  mounted() {
    this.taskId = request("id");
    this.type = request("type");
    this.categoryId = request("categoryId");
    if(this.type == TaskTypeEnum.KE_GUAN_TI || this.type == TaskTypeEnum.ZHU_GUAN_TI || this.type == TaskTypeEnum.ZUO_YE_KG || this.type == TaskTypeEnum.ZUO_YE_ZG){
      if(this.categoryId == TaskCategoryEnum.DU_XUE){
        this.isShowOperation=true;
      }
    }
    this.initData();
  }
}
</script>

<style scoped>
@import "../../../../static/css/add.css";
</style>
